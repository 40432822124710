import { createApp } from 'vue'
import App from './App.vue'


createApp(App).mount('#body')

/*
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
*/
